import { Border, BrowserFiles, Grid, Objects } from "@mjtdev/engine";
import { updateChatState, useChatState } from "../ai/useChatState";

export const FactsEditor = () => {
  const { scriptFacts } = useChatState();
  console.log({ scriptFacts });
  const contents = Objects.entries(scriptFacts).map((entry, i) => {
    const [key, value] = entry;
    return [
      <div style={{ textAlign: "right" }} key={key}>
        {key}
      </div>,
      <textarea
        onChange={(evt) => {
          updateChatState((state) => {
            state.scriptFacts[key] = evt.target.value;
          });
        }}
        value={String(value)}
        key={`value-${i}`}
      ></textarea>,
    ];
  });
  return (
    <Border title="facts">
      <Grid gap={"1em"} direction="column" cellSize={"min-content"} count={2}>
        {contents}
      </Grid>
    </Border>
  );
};
