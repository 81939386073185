import { AppEventType, AppEvents } from "../event/AppEvents";
import { addLogEntry } from "../ui/log/addLogEntry";
import { openErrorPopup } from "../ui/openErrorPopup";
import { SocketMessage } from "./Message";
import { updateBackendState } from "./updateBackendState";

export const setupSocket = (socket: WebSocket) => {
  socket.addEventListener("open", (evt) => {
    console.log({ evt });
    addLogEntry({
      text: "opened backend socket",
    });
  });
  socket.addEventListener("message", async (evt) => {
    console.log({ evt });
    console.log(evt.data);
    const message = JSON.parse(evt.data) as SocketMessage;
    console.log({ message });
    AppEvents.dispatchAppEvent(message.type as AppEventType, message.data);
  });
  socket.addEventListener("error", (evt) => {
    console.error({ evt });
    openErrorPopup("Error on backend socket");
  });
  socket.addEventListener("close", (evt) => {
    console.log("closed socket");
    console.log({ evt });
    addLogEntry({
      text: "closed backend socket",
    });
    updateBackendState((state) => {
      state.socket = undefined;
    });
  });
};
