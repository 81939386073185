import { Nlps, isUndefined, last } from "@mjtdev/engine";

/**
 * 0 == not similar, 1 == exactly the same
 */
export const similarityToStrings = (
  response: string,
  pool: string[]
): number => {
  if (isUndefined(response) || response.length === 0 || pool.length === 0) {
    return 0;
  }
  const responseWords = response.split(" ");
  const similarities = pool.map((br) => {
    const words = br.split(" ");
    const [distance, confidence] = Nlps.levenshteinDistance(
      words,
      responseWords
    );
    const similarity =
      1 - distance / Math.max(words.length, responseWords.length);
    return similarity;
  });
  const similarity = last(similarities.sort());
  console.log(`similarity: ${similarity} `);
  return similarity || 0;
};
