import { Border, Grid, createState } from "@mjtdev/engine";
import { startHearing } from "../startHearing";
import { updateChatState } from "../ai/useChatState";
import { useVttState } from "../useVttState";

export const [usePttState, updatePttState, getPttState] = createState({
  active: false,
  talking: false,
});

export const PushToTalk = () => {
  const { active: pttActive, talking } = usePttState();
  const { active: vttActive } = useVttState();
  const title = (
    <span>
      Push to talk
      <input
        type="checkbox"
        checked={pttActive}
        onChange={(evt) => {
          updatePttState((state) => {
            state.active = evt.target.checked;
          });
        }}
      />
    </span>
  );
  return (
    <Border title={title}>
      <Grid cellSize={"min-content"} direction="row">
        <button
          onPointerDown={(evt) => {
            updatePttState((state) => {
              state.talking = true;
            });
            updateChatState((state) => {
              state.active = true;
            });
          }}
          onPointerUp={(evt) => {
            updatePttState((state) => {
              state.talking = false;
            });
          }}
          disabled={!pttActive || !vttActive}
        >
          Talk{talking ? "ing" : ""}
        </button>
      </Grid>
    </Border>
  );
};
