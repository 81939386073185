import { getChildren } from "./getChildren";
import { evalNode } from "./evalNode";
import { updateNodeStatus } from "./updateNodeStatus";

export const evalSelector = async (id: string) => {
  const children = getChildren(id);
  for (let child of children) {
    const status = await evalNode(child);
    if (status === "running" || status === "success") {
      updateNodeStatus(id, status);
      return status;
    }
  }
  updateNodeStatus(id, "failure");
  return "failure";
};
