import { renderTemplate } from "../template/renderTemplate";
import { getChatState } from "./useChatState";


export const getBotDirection = () => {
  const { goals, scriptFacts } = getChatState();
  const activeGoals = goals.filter((g) => g.active);
  const directions = activeGoals.map((activeGoal) => {
    return renderTemplate(activeGoal?.direction, scriptFacts) || "";
  });
  return directions.join(", ");
};
