import { createState } from "@mjtdev/engine";

export const [useTtsState, updateTtsState, getTtsState] = createState({
  voices: [] as SpeechSynthesisVoice[],
  selectedVoice: 160,
  interrupted: false,
  pitch: 0.8,
  rate: 1.12,
  delay: 500,
  speaking: false,
});
