import { isUndefined } from "@mjtdev/engine";
import { SocketMessage } from "./Message";
import { getBackendState } from "./updateBackendState";

export const sendBackendMessage = (message: SocketMessage) => {
  const { socket } = getBackendState();
  if (isUndefined(socket)) {
    console.log({ message });
    console.log("refusing to send message, no socket.");
    return;
  }
  socket.send(JSON.stringify(message));
};
