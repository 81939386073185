import { AiMessage } from "../ai/AiTypes";
import { aiUtterancesToString } from "../ai/aiUtterancesToString";
import { askAi } from "../ai/askAi";
import { factsToAiSystemMessage } from "../ai/factsToAiSystemMessage";
import { maxEntries } from "../ai/maxEntries";
import { getChatState, updateChatState } from "../ai/useChatState";
import { getLastElements } from "../common/getLastElements";
import { renderTemplate } from "../template/renderTemplate";
import { Goal } from "./Goal";
import { GoalStatus } from "./GoalStatus";
import { askLearned } from "./askLearned";
import { toGoalStatus } from "./toGoalStatus";

export const askGoalStatus = async (goal: Goal): Promise<GoalStatus> => {
  const {
    aiAnalysisModel,
    maxConversationContextSize,
    maxFactsContextSize,
    conversation,
    scriptFacts,
    learnedFacts,
  } = getChatState();
  const { messages } = conversation;
  const { question } = goal;

  const factsMessage = factsToAiSystemMessage(
    maxEntries({ ...learnedFacts, ...scriptFacts }, maxFactsContextSize)
  );

  const renderedQuestion = renderTemplate(question, scriptFacts);

  const questionMessage: AiMessage = {
    role: "user",
    content: renderedQuestion,
  };

  const sysDirectionMsg: AiMessage = {
    role: "system",
    content: `You are an AI conversation expert program that only goes by the facts you find in the chat conversation itself and are always truthful.
You only respond with JSON
You only answer status= 'complete' if you know the answer to the question.
You only answer status= 'active' if you are unsure about the answer to the question.`,
  };

  const sysFormatMsg: AiMessage = {
    role: "system",
    content: `Example JSON response Format:
{
  "explanation": "a brief explanation of your reasoning",
  "answer": "the answer to the question",
  "status": "active or complete"
}`,
  };

  console.log("---GOAL STATUS---");
  const response = await askAi({
    model: aiAnalysisModel,
    messages: [
      ...messages,
      factsMessage,
      sysDirectionMsg,
      sysFormatMsg,
      questionMessage,
    ],
  });
  const fullResponse = response;
  // const fullResponse = `${responsePrefix}${response}`;
  console.log({ fullResponse });
  const status = toGoalStatus(fullResponse);
  console.log({ status });
  updateChatState((state) => {
    const gIdx = state.goals.findIndex((g) => g.id === goal.id);
    state.goals[gIdx] = {
      ...state.goals[gIdx],
      status,
    };
  });
  await askLearned();
  return status;
};
