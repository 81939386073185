import { Center, Grid, isUndefined, openCenteredPopup } from "@mjtdev/engine";
import { useChatState } from "../ai/useChatState";
import { BackendDisplay } from "./BackendDisplay";
import { BehaviorTreeDisplay } from "./BehaviorTreeDisplay";
import { ChatBox } from "./ChatBox";
import { Dialer } from "./Dialer";
import { FactsDisplay } from "./FactsDisplay";
import { GoalsDisplay } from "./GoalsDisplay";
import { showAiLog } from "./log/showAiLog";

export const ChatWindow = () => {
  const { agentScript } = useChatState();

  if (isUndefined(agentScript)) {
    return <>No Agent Script. Is config loaded?</>;
  }
  return (
    <Grid
      style={{
        width: "95vw",
        overflow: "auto",
        margin: "2em",
      }}
      direction="row"
      gap={"1em"}
      cellSize={"min-content"}
    >
      <Center>
        <button
          onClick={() => {
            openCenteredPopup(<Dialer />);
          }}
          style={{ whiteSpace: "nowrap", width: "min-content" }}
        >
          Open Dialer
        </button>
        <BackendDisplay />
        <button onClick={() => showAiLog()}>Open Log</button>
      </Center>
      <Grid
        style={{ margin: "2em" }}
        // cellSize={["30vw", "30vw"]}
        direction="column"
        gap={"1em"}
      >
        <Grid direction="row" cellSize={"min-content"}>
          <BehaviorTreeDisplay />
          <Grid direction="column" cellSize={"min-content"}>
            <FactsDisplay />
            <GoalsDisplay />
          </Grid>
        </Grid>
        <ChatBox />
      </Grid>
    </Grid>
  );
};
