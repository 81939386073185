import { writeFile } from "./writeFile";
import { getFile } from "./getFile";
import { toFileContent } from "./toFileContent";

export const writeFilePath = async ({
  parent,
  fileName,
  content,
}: {
  content: string | object | Blob;
  parent: FileSystemDirectoryHandle;
  fileName: string;
}) => {
  const file = await getFile({ parent, name: fileName, create: true });
  writeFile({ file, content: toFileContent(content) });
};
