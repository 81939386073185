import { Border, Grid, isUndefined } from "@mjtdev/engine";
import { getChatState, updateChatState } from "../ai/useChatState";
import { openFormPopup } from "./form/openFormPopup";

export const GoalsEditor = () => {
  const { goals } = getChatState();
  const editors = goals.map((g, i) => [
    <div style={{ textAlign: "right" }} key={`id-${i}`}>
      {g.id}
    </div>,
    <div
      onClick={async () => {
        const { id, name, direction, question } = g;
        const resp = await openFormPopup({
          title: "Goal",
          record: {
            id,
            name,
            direction,
            question,
          },
        });
        if (isUndefined(resp)) {
          return;
        }
        updateChatState((state) => {
          const gIdx = state.goals.findIndex((gg) => gg.id === g.id);
          state.goals[gIdx] = { ...g, ...resp };
        });
      }}
      key={`edit-${i}`}
    >
      ✏️
    </div>,
    <div
      onClick={() => {
        updateChatState((state) => {
          state.goals = state.goals.filter((gg) => gg.id !== g.id);
        });
      }}
      key={`delete-${i}`}
    >
      ❌
    </div>,
  ]);

  const add = [
    <button
      onClick={async () => {
        // const { id, name, direction, question } = g;
        const resp = await openFormPopup({
          title: "Goal",
          record: {
            id: `${crypto.randomUUID()}`,
            name: "Unamed",
            direction: "Your goal is to ...",
            question: "Did {prospect} ...",
          },
        });
        if (isUndefined(resp)) {
          return;
        }
        updateChatState((state) => {
          state.goals.push({
            active: false,
            ...resp,
          });
        });
      }}
      key={"add"}
    >
      Add
    </button>,
    <div key={"add-blank"}></div>,
    <div key={"add-blank2"}></div>,
  ];

  return (
    <Border resizable={false} title="goals">
      <Grid
        style={{ userSelect: "none" }}
        gap={"1em"}
        cellSize={"min-content"}
        direction="column"
        count={3}
      >
        {add}
        {editors}
      </Grid>
    </Border>
  );
};

// export const GoalEditor = ({ goal }: { goal: Goal }) => {
//   const { direction, id, name, question } = goal;
//   return (
//     <Grid cellSize={"min-content"} style={{ maxWidth: "10em" }} direction="row">
//       <div>✏️{id}</div>
//       <div>{name}</div>
//     </Grid>
//   );
// };
