import { renderTemplate } from "../template/renderTemplate";
import { AiConversation, AiMessage } from "./AiTypes";
import { getRenderedGuidance } from "./getRenderedGuidance";
import { getChatState, updateChatState } from "./useChatState";

export const createConversation = (): AiConversation => {
  const { scriptFacts } = getChatState();
  const generalSysMsg: AiMessage = {
    content: renderTemplate(
      "Follow the directions and respond as {aiName} Do not call the customer by name. Be conversational and polite. Talk lke a real person not like a robot.",
      scriptFacts
    ),
    role: "system",
  };

  const guidance = getRenderedGuidance();
  const guidanceMessage: AiMessage = {
    role: "system",
    content: guidance,
  };

  const conversation: AiConversation = {
    messages: [guidanceMessage, generalSysMsg],
  };

  updateChatState((state) => {
    state.conversation = conversation;
  });
  return conversation;
};
