import { Border, Grid, isUndefined } from "@mjtdev/engine";
import { BehaviorNode } from "../ai/behavior/BehaviorTypes";
import { useBehaviorState } from "../ai/behavior/getBehaviorState";
import { COLORMAP } from "./COLORMAP";

export const BehaviorNodeDisplay = ({ node }: { node: BehaviorNode }) => {
  const { id, parent, type, name, status } = node;
  const { nodes, message } = useBehaviorState();
  const children = nodes.filter((n) => n.parent === node.id);
  const childDisplays = children.map((c, i) => (
    <BehaviorNodeDisplay key={i} node={c} />
  ));
  const color = COLORMAP[status] ?? "white";
  const title = name ?? id;
  return (
    <Grid cellSize={"max-content"} gap={"1em"} direction="row">
      <Grid
        style={{
          // whiteSpace: "nowrap",
          // border: `3px solid ${color}`,
          width: "min-content",
          height: "min-content",
        }}
        cellSize={"min-content"}
        gap={"1em"}
        direction="row"
      >
        <Border
          resizable={false}
          style={{
            border: `3px solid ${color}`,
          }}
          title={title}
        >
          <div>({type})</div>
          <div>{status}</div>
        </Border>
      </Grid>
      <Grid gap={"0.5em"} cellSize={"min-content"} direction="column">
        {childDisplays}
      </Grid>
    </Grid>
  );
};

export const BehaviorTreeDisplay = () => {
  const { nodes, message } = useBehaviorState();
  const root = nodes.find((n) => n.parent === undefined);
  if (isUndefined(root)) {
    return "NO TREE ROOT";
  }

  return (
    <Border collapsable={true} title="Behavior">
      <Grid direction="row" cellSize={"min-content"}>
        <BehaviorNodeDisplay node={root} />
      </Grid>
    </Border>
  );
};
