import { useEffect } from "react";
import { getVoicesAsync } from "../getVoicesAsync";
import { updateTtsState, useTtsState } from "../useTtsState";
import { Border } from "@mjtdev/engine";

export const VoiceList = () => {
  const { voices, selectedVoice } = useTtsState();
  useEffect(() => {
    getVoicesAsync().then((voices) => {
      updateTtsState((state) => {
        state.voices = voices;
      });
    });
  }, []);

  const voiceOptions = voices.map((v, i) => {
    return (
      <option key={i} value={i}>
        {v.name} {v.lang}
      </option>
    );
  });

  return (
    <Border title={`voice ${selectedVoice}`}>
      <select
        value={String(selectedVoice)}
        onChange={(e) => {
          console.log(e.target.value);
          updateTtsState((state) => {
            state.selectedVoice = Number(e.target.value);
          });
        }}
      >
        {voiceOptions}
      </select>
    </Border>
  );
};
