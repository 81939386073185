import { isUndefined } from "@mjtdev/engine";


export const valueToString = (value: unknown): string => {
  if (isUndefined(value)) {
    return undefined;
  }
  if (typeof value === "string") {
    return value;
  }
  return JSON.stringify(value);
};
