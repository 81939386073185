import { Grid } from "@mjtdev/engine";
import { loadStates } from "../state/loadStates";
import { updateTtsState } from "../useTtsState";
import { HearingControl } from "./HearingControl";
import { PushToTalk } from "./PushToTalk";
import { useChatState } from "../ai/useChatState";

export const ControlsDisplay = () => {
  const { aiConversationModel } = useChatState();
  return (
    <Grid cellSize={"min-content"} direction="column" gap="1em">
      <Grid
        style={{
          whiteSpace: "nowrap",
          width: "min-content",
          height: "min-content",
        }}
        cellSize={"min-content"}
        direction="column"
      >
        <PushToTalk />
        <HearingControl />
        <button
          style={{ height: "min-content" }}
          onClick={() =>
            updateTtsState((state) => {
              console.log("interupted!");
              state.interrupted = true;
            })
          }
        >
          Interrupt
        </button>
        <button
          style={{ height: "min-content" }}
          onClick={() => {
            loadStates();
          }}
        >
          Reload
        </button>
        <div style={{ marginLeft: "1em" }}>{aiConversationModel}</div>
      </Grid>
    </Grid>
  );
};
