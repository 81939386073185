import { closePopup, openCenteredPopup } from "@mjtdev/engine";
import { stopHearing } from "../../stopHearing";
import { getChatState, updateChatState } from "../useChatState";

export const endChat = () => {
  console.log("ending chat!");
  if (!getChatState().active) {
    return;
  }
  updateChatState((state) => {
    state.active = false;
  });
  stopHearing();
  const id = openCenteredPopup(
    <div
      style={{
        padding: "2em",
        backgroundColor: "black",
        border: "1px solid white",
      }}
    >
      Finished{" "}
      <button
        onClick={() => {
          closePopup(id);
        }}
      >
        Close
      </button>
    </div>
  );
};
