import { Border, Grid } from "@mjtdev/engine";
import { useState } from "react";
import { sendBackendMessage } from "../backend/sendBackendMessage";
import { updateTtsState } from "../useTtsState";

export const Dialer = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <Border style={{ padding: "1em", backgroundColor: "black" }} title="dial">
      <Grid gap={"1em"} direction="row" cellSize={"min-content"}>
        <input
          autoFocus={true}
          placeholder="555-1212"
          size={30}
          onChange={(evt) => {
            setPhoneNumber(evt.target.value);
          }}
          type="text"
        />
        <button
          onClick={() => {
            updateTtsState((state) => {
              state.delay = 0;
            });
            sendBackendMessage({ type: "call", data: phoneNumber });
          }}
        >
          Call
        </button>
        <button
          onClick={() => {
            // hangupPhone();
            sendBackendMessage({ type: "hangup", data: phoneNumber });
          }}
        >
          Hang Up
        </button>
      </Grid>
    </Border>
  );
};
