import { BrowserFiles, isUndefined } from "@mjtdev/engine";
import { askForPermission } from "./askForPermission";

export const getSaveDir = async () => {
  const parent = await BrowserFiles.openDirectoryHandle({ multiple: false });
  if (isUndefined(parent)) {
    return;
  }
  const perm = await askForPermission(parent, "readwrite");
  if (isUndefined(perm) || perm !== "granted") {
    return;
  }
  return parent;
};
