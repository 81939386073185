
export const convertValueToType = <K extends string | number | symbol = string>(
  key: K,
  value: string,
  typeMap: Record<
    K, "string" |
    "number" |
    "bigint" |
    "boolean" |
    "symbol" |
    "undefined" |
    "object" |
    "function"
  >
): (typeof typeMap)[K] => {
  const type = typeMap[key];
  if (type === "string") {
    return value as (typeof typeMap)[K];
  }
  if (type === "number") {
    return Number(value) as any as (typeof typeMap)[K];
  }
  if (type === "boolean") {
    return Boolean(value) as any as (typeof typeMap)[K];
  }
  return value as any as (typeof typeMap)[K];
};
