import { waitFor } from "../ui/waitFor";
import { OpenApiCompletionsRequest } from "./OpenApiCompletionsRequest";
import { askOpenAi } from "./askOpenAi";
import { getChatState } from "./useChatState";
import { askRestfulOpenApiCompletions } from "./askRestfulOpenApiCompletions";

export const askAi = (
  params: Partial<OpenApiCompletionsRequest> = {}
): Promise<string> => {
  console.log({ params });
  const { aiType } = getChatState();
  if (aiType === "custom") {
    // TODO turn back on custom mode
    // return waitFor(
    //   () =>
    //     askRestfulOpenApiCompletions(prompt, {
    //       max_tokens: 200,
    //       ...options,
    //     }),
    //   {
    //     message: "Waiting on custom AI",
    //   }
    // );
  }
  if (aiType === "openAI") {
    return waitFor(() => askOpenAi(params), {
      message: "Waiting on AI",
    });
  }
  throw new Error(`unknown AI type: ${aiType}`);
};
