import { getChatState, updateChatState } from "../ai/useChatState";
import { setupSocket } from "./setupSocket";
import { updateBackendState } from "./updateBackendState";

export const openBackendConnection = () => {
  // const socket = new WebSocket("ws://localhost:3000/ws");
  const { backendHost } = getChatState();
  // const socket = new WebSocket("ws://localhost:8787/ws");
  const socket = new WebSocket(`wss://${backendHost}/ws`);
  console.log({ socket, backendHost });
  setupSocket(socket);
  updateBackendState((state) => {
    state.socket = socket;
  });
  updateChatState((state) => {
    state.active = true;
    state.interactionType = "backend";
  });
};
