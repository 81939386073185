import { stopHearing } from "../stopHearing";
import { useVttState } from "../useVttState";
import { startChat } from "../ai/startChat";

export const HearingControl = () => {
  const { active } = useVttState();
  if (active) {
    return (
      <button style={{ height: "min-content" }} onClick={() => stopHearing()}>
        Deafen Bot
      </button>
    );
  }
  return (
    <button style={{ height: "min-content" }} onClick={() => startChat()}>
      Allow Bot to Hear
    </button>
  );
};
