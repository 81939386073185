import { evalSelector } from "./evalSelector";
import { evalTask } from "./evalTask";
import { BehaviorNode, BehaviorStatus } from "./BehaviorTypes";
import { evalSequence } from "./evalSequence";

export const evalNode = async (node: BehaviorNode): Promise<BehaviorStatus> => {
  const { type } = node;
  if (type === "task") {
    return evalTask(node.id);
  }
  if (type === "selector") {
    return evalSelector(node.id);
  }
  if (type === "sequence") {
    return evalSequence(node.id);
  }
  console.log({ node });
  throw new Error(`Unknown node type ${type}`);
};
