import { isUndefined } from "@mjtdev/engine";
import { GoalStatus } from "../../call-script/GoalStatus";
import { BehaviorStatus } from "./BehaviorTypes";
import { isGoalComplete } from "./isGoalComplete";

export const goalStatusToBehaviorStatus = (
  goalStatus: GoalStatus
): BehaviorStatus => {
  if (isUndefined(goalStatus)) {
    return "running";
  }
  if (isGoalComplete(goalStatus)) {
    return "success";
  }
  return "running";
};
