import { isDefined, isUndefined, safeAsync } from "@mjtdev/engine";
import OpenAI from "openai";
import { openErrorPopup } from "../ui/openErrorPopup";
import { OpenApiCompletionsRequest } from "./OpenApiCompletionsRequest";
import { getChatState } from "./useChatState";
import { AiConversation } from "./AiTypes";

let OPEN_AI: OpenAI = undefined;
export const getOpenAi = () => {
  if (isDefined(OPEN_AI)) {
    return OPEN_AI;
  }
  const { aiKey } = getChatState();
  const openai = new OpenAI({
    apiKey: aiKey,
    dangerouslyAllowBrowser: true,
    timeout: 5000,
    // defaultHeaders: {
    //   "Access-Control-Allow-Origin": "*",
    // },
    // baseURL: "http://213.181.122.2:56434",
    // baseURL: "http://gamepc.local:5000",
  });
  OPEN_AI = openai;
  return OPEN_AI;
};

export const toOpenAiMessages = (conversation: AiConversation) => {
  const { messages: utterances } = conversation;
};

export const askOpenAi = async (
  params: Partial<OpenApiCompletionsRequest> &
    Partial<{ attemptsLeft: number }> = {}
) => {
  const { temperature = 0.5, presensePenalty = 2 } = getChatState();
  const { model, messages, attemptsLeft = 3 } = params;

  const openai = getOpenAi();

  const chatCompletion = await safeAsync(() =>
    openai.chat.completions.create({
      // messages: [{ role: "user", content: prompt }],
      messages,
      model,
      temperature,
      presence_penalty: presensePenalty,
    })
  );
  if (isUndefined(chatCompletion)) {
    if (attemptsLeft <= 0) {
      openErrorPopup("Max retries for connecting to openAI reached");
      return "";
    }
    console.log("retrying openAPI after failure...");
    return askOpenAi({ ...params, attemptsLeft: attemptsLeft - 1 });
  }
  console.log({ chatCompletion });
  const { choices } = chatCompletion;
  console.log({ choices });
  console.log(choices[0].message.role);
  console.log(choices[0].message.content);
  return choices[0].message.content;
};
