import { Border, Grid } from "@mjtdev/engine";
import { useChatState } from "../ai/useChatState";
import { GoalDisplay } from "./GoalDisplay";

export const GoalsDisplay = () => {
  const { goals } = useChatState();
  const contents = goals.map((g, i) => <GoalDisplay key={i} goal={g} />);

  return (
    <Border collapsable={true} title="Goals">
      <Grid
        style={{ maxHeight: "60vh", overflow: "auto" }}
        gap={"1em"}
        direction="column"
        cellSize={"min-content"}
        count={1}
      >
        {contents}
      </Grid>
    </Border>
  );
};


