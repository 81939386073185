import { ImageGens, MonitorFunction, TextGens } from "@mjtdev/engine";
// import { useMonitorState } from "./useMonitorState";
// import { getAppState } from "../ui/useAppState";

export const setupAi = () => {
  console.log("setting up AI...");
  // const baseUrl = "http://gamepc.local:5000";
  const automaticBaseUrl = "http://gamepc.local:8888";
  const baseUrl = "http://211.120.209.63:41814";

  // const { imageGenBaseUrl, textGenBaseUrl, debug } = getAppState();
  // console.log({ imageGenBaseUrl, textGenBaseUrl, debug });

  const { useTextGenState } = TextGens;

  const monitor: MonitorFunction = (message, state, traceId) => {
    // console.log(message);
    // const { consumer } = useMonitorState.getState();
    // consumer(message, state, traceId);
  };

  useTextGenState.setState((state) => ({
    ...state,
    baseUrl,
    debug: false,
    monitor,
    textgenParams: {
      // temperature: 0.7,
      temperature: 0.7,
      // top_p: 0.9,
      repetition_penalty: 1.15,
      // stopping_strings: [
      //   // "###ASSISTANT:",
      //   // "###HUMAN:",
      //   // "###",
      //   // "HUMAN:",
      //   // "ASSISTANT:",
      //   // "Human:",
      //   // "Assistant:",
      //   // "assistant:",
      //   // "User:",
      //   // "<|im_end|>",
      // ],
    },
  }));

  // const { useImageGenState } = ImageGens;
  // useImageGenState.setState((state) => ({
  //   ...state,
  //   automaticBaseUrl: imageGenBaseUrl,
  //   debug,

  //   monitor,
  // }));
};
