import { Center, Grid, centerPopup, closePopup } from "@mjtdev/engine";
import { CSSProperties, useEffect, useRef } from "react";

export const PopupFormDisplay = <
  K extends string | number | symbol = string,
  V extends string | boolean = string
>({
  title,
  name,
  fieldComponents,
  onSubmit = () => {},
  style,
}: {
  onSubmit?: (ok: boolean) => void;
  name: string;
  title: string;
  fieldComponents: JSX.Element[];
  style?: CSSProperties;
}) => {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    centerPopup(name, ref.current);
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        padding: "1em",
        backgroundColor: "black",
        border: "1px solid white",
        maxHeight: "80vh",
        maxWidth: "80vw",
        overflow: "visible",
        whiteSpace: "pre-line",
        ...style,
      }}
    >
      <Grid direction="row" cellSize={"min-content"} gap="1em">
        <Center>{title}</Center>
        <form
          onKeyDown={(evt) => {
            if (evt.ctrlKey && evt.key.toLocaleLowerCase() === "enter") {
              evt.preventDefault();
              closePopup(name);
              onSubmit(true);
            }
            if (evt.key.toLocaleLowerCase() === "escape") {
              closePopup(name);
              onSubmit(false);
            }
          }}
          onSubmit={(evt) => {
            evt.preventDefault();
            closePopup(name);
            onSubmit(true);
          }}
        >
          <Grid direction="row" cellSize={"min-content"} gap="0.1em">
            {fieldComponents}
          </Grid>
          <Center style={{ padding: "1em" }}>
            <Grid gap={"1em"} cellSize={"min-content"}>
              <input type="submit" value="OK" />
              <input
                type="button"
                value="Cancel"
                onClick={() => {
                  closePopup(name);
                  onSubmit(false);
                }}
              />
            </Grid>
          </Center>
        </form>
      </Grid>
    </div>
  );
};
