import { renderTemplate } from "../template/renderTemplate";
import { getChatState } from "./useChatState";

export const getRenderedGuidance = () => {
  const {
    aiConversationModel,
    conversation,
    agentScript,
    scriptFacts = {},
    guidance,
    learnedFacts = {},
    similarityThreshold,
    maxConversationContextSize,
    maxFactsContextSize,
  } = getChatState();
  // const { messages } = conversation;
  // const factContext = factsToContext(
  //   maxEntries({ ...learnedFacts, ...scriptFacts }, maxFactsContextSize)
  // );
  const renderedScript = renderTemplate(agentScript, scriptFacts);
  return renderTemplate(guidance, {
    ...scriptFacts,
    script: renderedScript,
  });
};
