import { Idb, Idbs, createState, isUndefined } from "@mjtdev/engine";

export const [useAppState, updateAppState, getAppState] = createState({
  localDirectory: undefined as FileSystemDirectoryHandle,
});

export type AppState = ReturnType<typeof getAppState>;

const AppDB: Idb<AppState> = {
  dbName: "intellibot",
  storeName: "app",
};

export const saveAppState = (key = "state") => {
  return Idbs.put(AppDB, key, getAppState());
};

export const loadAppState = async (key = "state") => {
  const s = await Idbs.get(AppDB, key);
  if (isUndefined(s)) {
    console.log("No App State found");
    return;
  }
  return updateAppState((state) => s);
  // Idbs.put(AppDB, "state", getAppState());
};
