import { Border, BrowserFiles, isDefined, isUndefined } from "@mjtdev/engine";
import {
  getAppState,
  saveAppState,
  updateAppState,
  useAppState,
} from "../file/AppState";
import { loadStates } from "../state/loadStates";
import { saveStates } from "../state/saveStates";

export const LocalDirDisplay = () => {
  const { localDirectory } = useAppState();

  const buttonText = isDefined(localDirectory)
    ? localDirectory.name
    : "Choose Config Directory";

  return (
    <button
      onClick={async () => {
        const handle = await BrowserFiles.openDirectoryHandle({
          multiple: false,
        });
        if (isUndefined(handle)) {
          return;
        }
        updateAppState((state) => {
          state.localDirectory = handle;
        });
        await saveAppState();
        loadStates();
      }}
    >
      {buttonText}
    </button>
  );
};

export const SaveLoadDisplay = () => {
  return (
    <Border
      resizable={false}
      style={{ whiteSpace: "nowrap" }}
      title={"save/load config"}
    >
      <LocalDirDisplay />
      <button
        style={{ width: "min-content", height: "min-content" }}
        onClick={() => saveStates()}
      >
        Save
      </button>
      <button
        style={{ width: "min-content", height: "min-content" }}
        onClick={() => loadStates()}
      >
        Load
      </button>
    </Border>
  );
};
