import { isUndefined } from "@mjtdev/engine";
import { GoalStatus } from "../../call-script/GoalStatus";

export const isGoalComplete = (goalStatus: GoalStatus) => {
  if (isUndefined(goalStatus)) {
    return false;
  }
  const { answer = "", status = "", explanation } = goalStatus;
  return /complete/i.test(status);
};
