import { getChildren } from "./getChildren";
import { evalNode } from "./evalNode";
import { updateNodeStatus } from "./updateNodeStatus";

export const evalSequence = async (id: string) => {
  const children = getChildren(id);
  for (let child of children) {
    console.log(`evaluating sequence child: ${child.id}`);
    const status = await evalNode(child);
    if (status === "running") {
      updateNodeStatus(id, status);
      return "running";
    } else if (status === "failure") {
      updateNodeStatus(id, status);
      return "failure";
    }
  }
  updateNodeStatus(id, "success");
  return "success";
};
