import {
  Center,
  Colors,
  Grid,
  closePopup,
  openCenteredPopup,
} from "@mjtdev/engine";

export const unknownToString = (value: unknown) => {
  if (typeof value === "string") {
    return value;
  }
  if (value instanceof Error) {
    return [value.message, value.stack].join("\n");
  }
  return JSON.stringify(value);
};

export const openErrorPopup = (error: unknown) => {
  const name = "error-popup";
  openCenteredPopup(
    <Grid
      gap={"1em"}
      cellSize={"min-content"}
      style={{
        padding: "1em",
        backgroundColor: Colors.from("red").darken(0.8).toString(),
      }}
      direction="row"
    >
      <div>Unexpected Error</div>
      <pre>{unknownToString(error)}</pre>
      <Center>
        <button
          style={{ width: "min-content" }}
          onClick={() => closePopup(name)}
        >
          Close
        </button>
      </Center>
    </Grid>,
    { name }
  );
};
