import { updateBehaviorState } from "./getBehaviorState";
import { BehaviorStatus } from "./BehaviorTypes";

export const updateNodeStatus = (id: string, status: BehaviorStatus) => {
  console.log(`setting ${id} to ${status}`);
  updateBehaviorState((state) => {
    const n = state.nodes.find((n) => n.id === id);
    n.status = status;
    state.message = "finished evaluation";
  });
};
