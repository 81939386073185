import { isUndefined } from "@mjtdev/engine";
import { askForPermission } from "./askForPermission";

export const getFile = async (params: {
  parent: FileSystemDirectoryHandle;
  name: string;
  create?: boolean;
}) => {
  const { parent, name, create = true } = params;
  console.log(`getting file ${name}`);
  const perm = await askForPermission(parent);
  if (isUndefined(perm)) {
    return;
  }

  return parent.getFileHandle(name, {
    create,
  });
};
