import { Facts } from "../template/Facts";
import { AiMessage } from "./AiTypes";

export const factsToAiSystemMessage = (facts: Facts): AiMessage => {
  const content = JSON.stringify(facts);
  return {
    role: "system",
    content,
  };
  // const items = Objects.entries(facts)
  //   .map((entry) => {
  //     const [key, value] = entry;
  //     return `<fact name="${key}" value="${String(value)}"/>`;
  //   })
  //   .join("\n");
  // return `<facts>\n${items}\n</facts>`;
};
