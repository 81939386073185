import { isDefined, isUndefined } from "@mjtdev/engine";
import { askGoalStatus } from "../../call-script/askGoalStatus";
import { activateGoal } from "../../ui/activateGoal";
import { BehaviorStatus, TaskNode } from "./BehaviorTypes";
import { findGoal } from "./findGoal";
import { findNode } from "./findNode";
import { updateBehaviorState } from "./getBehaviorState";
import { goalStatusToBehaviorStatus } from "./goalStatusToBehaviorStatus";
import { updateNodeStatus } from "./updateNodeStatus";
import { endChat } from "./endChat";
import { openErrorPopup } from "../../ui/openErrorPopup";
import { isGoalComplete } from "./isGoalComplete";

export const evalTask = async (id: string): Promise<BehaviorStatus> => {
  const node = findNode(id) as TaskNode;
  console.log(`evaluating task: ${id} with a status of ${node.status}`);
  if (node.status === "success") {
    return "success";
  }
  if (node.status === "failure") {
    return "failure";
  }

  if (isDefined(node.goal)) {
    const goal = findGoal(node.goal);
    if (isUndefined(goal)) {
      openErrorPopup(`Error finding goal: ${node.goal} `);
      return "failure";
    }
    // activateGoal(goal.id);
    updateBehaviorState((state) => {
      state.message = `evaluating: ${id}`;
    });
    // TODO add 'functions' to tasks
    if (node.endChat) {
      // HACK TODO better end chat
      if (/yes/i.test(goal.status.answer)) {
        endChat();
        updateNodeStatus(id, "success");
        return "success";
      } else {
        updateNodeStatus(id, "running");
        activateGoal(goal.id);
        return "running";
      }
    }
    if (isUndefined(node.status)) {
      console.log(`setting ${id} to running`);
      updateNodeStatus(id, "running");
      return "running";
    }
    const nodeStatus = goalStatusToBehaviorStatus(goal.status);
    updateNodeStatus(id, nodeStatus);
    updateBehaviorState((state) => {
      state.message = "finished evaluation";
    });
    return nodeStatus;
  }
};
