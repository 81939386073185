import { Border, Grid } from "@mjtdev/engine";
import { useState } from "react";
import { askNextBotResponse } from "../ai/askNextBotResponse";
import { speak } from "../speak";
import { updateTtsState, useTtsState } from "../useTtsState";
import { VoiceList } from "./VoiceList";

export const VoiceControlsDisplay = () => {
  const [text, setText] = useState("all your base are belong to us");

  const { pitch, rate, delay } = useTtsState();

  return (
    <Border style={{ whiteSpace: "nowrap" }} title={"voice control"}>
      <Grid
        style={{ whiteSpace: "nowrap" }}
        direction="column"
        cellSize={"min-content"}
        gap={"1em"}
      >
        <Grid
          style={{ width: "20em", height: "min-content" }}
          direction="row"
          cellSize={"min-content"}
        >
          <textarea
            placeholder="all your base are belong to us"
            onChange={(evt) => {
              setText(evt.target.value);
            }}
          />
          <Grid
            style={{ width: "min-content", height: "min-content" }}
            direction="column"
            cellSize={"min-content"}
          >
            <button onClick={() => speak(text)}>Speak</button>
            <button onClick={() => askNextBotResponse(text)}>Chat</button>
          </Grid>
        </Grid>
        <Grid
          style={{ width: "min-content", height: "min-content" }}
          cellSize={"min-content"}
          direction="row"
        >
          <VoiceList />
          <Grid
            style={{ width: "min-content", height: "min-content" }}
            direction="row"
            cellSize={"min-content"}
          >
            <Grid direction="column">
              <Border resizable={false} title={`pitch ${pitch}`}>
                <input
                  type="range"
                  min="0"
                  max="2"
                  step={"0.01"}
                  defaultValue={`${pitch}`}
                  onChange={(evt) => {
                    updateTtsState((state) => {
                      state.pitch = Number(evt.target.value);
                    });
                  }}
                />
              </Border>
              <Border resizable={false} title={`rate ${rate}`}>
                <input
                  type="range"
                  min="0"
                  max="2"
                  step={"0.01"}
                  defaultValue={`${rate}`}
                  onChange={(evt) => {
                    updateTtsState((state) => {
                      state.rate = Number(evt.target.value);
                    });
                  }}
                />
              </Border>
              <Border resizable={false} title={`delay ${delay}`}>
                <input
                  type="range"
                  min="0"
                  max="5000"
                  step={"1"}
                  defaultValue={`${delay}`}
                  onChange={(evt) => {
                    updateTtsState((state) => {
                      state.delay = Number(evt.target.value);
                      console.log(state.delay)
                    });
                  }}
                />
              </Border>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Border>
  );
};
