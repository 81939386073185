import { TextScreen, createState } from "@mjtdev/engine";
import { WELCOME_TEXT } from "../WELCOME_TEXT";
import { ChatWindow } from "./ChatWindow";
import { ConfigWindow } from "./ConfigWindow";
export const WINDOWS = {
  welcome: (
    <TextScreen
      actions={{
        Card: () => {
          // showCard();
        },
      }}
      text={WELCOME_TEXT}
    />
  ),
  chat: <ChatWindow />,
  config: <ConfigWindow />,
} as const;

export const [useMainViewportState] = createState({
  content: WINDOWS["config"],
});

export const switchWindow = (name: keyof typeof WINDOWS) => {
  useMainViewportState.setState((state) => ({
    ...state,
    content: WINDOWS[name],
  }));
};
