import { startHearing } from "./startHearing";
import { isHearing } from "./isHearing";
import { stopHearing } from "./stopHearing";
import { getTtsState, updateTtsState } from "./useTtsState";

export const speak = async (text = "Hello World") => {
  const wasHearing = isHearing();
  if (wasHearing) {
    await stopHearing();
  }
  const lines = text.split(/\./);
  for (let line of lines) {
    if (!(await speakLine(line))) {
      if (wasHearing) {
        startHearing();
      }
      return;
    }
  }
  if (wasHearing) {
    startHearing();
  }
};
export const chunkString = (str: string, size: number) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);
  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

export const speakLine = async (line: string) => {
  const { interrupted } = getTtsState();

  if (interrupted) {
    updateTtsState((state) => {
      state.interrupted = false;
    });
    return false;
  }
  // console.log(`line: ${line}, ${interrupted}`);
  if (line.length > 254) {
    const chunks = chunkString(line, 254);
    for (let chunk of chunks) {
      await speakLine(chunk);
    }
    return true;
  }
  const { voices, selectedVoice, pitch, rate } = getTtsState();
  const utterance = new SpeechSynthesisUtterance(line);
  utterance.pitch = pitch;
  utterance.rate = rate;
  utterance.voice = voices[selectedVoice];
  return new Promise((resolve, reject) => {
    utterance.addEventListener("end", () => {
      updateTtsState((state) => {
        state.speaking = false;
      });
      resolve(true);
    });
    utterance.addEventListener("error", (evt) => {
      console.log("speech error");
      console.log({ evt });
      updateTtsState((state) => {
        state.speaking = false;
      });
      reject(evt);
    });
    updateTtsState((state) => {
      state.speaking = true;
    });
    speechSynthesis.speak(utterance);
  });
};
