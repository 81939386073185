import { aiUtterancesToString } from "../ai/aiUtterancesToString";
import { askAi } from "../ai/askAi";
import { factsToAiSystemMessage } from "../ai/factsToAiSystemMessage";
import { getChatState, updateChatState } from "../ai/useChatState";
import { getLastElements } from "../common/getLastElements";
import { maxEntries } from "../ai/maxEntries";
import { xmlLikeToFacts } from "./xmlLikeToFacts";
import { AiMessage } from "../ai/AiTypes";
import { safe } from "@mjtdev/engine";

export const askLearned = async () => {
  const { aiAnalysisModel, conversation, scriptFacts, learnedFacts } =
    getChatState();
  const { messages } = conversation;
  // const utterancesText = aiUtterancesToString(getLastElements(messages, 5));
  const renderedQuestion =
    "Create an JSON report of only the new facts found inside the conversation. Do not include already known facts.";

  const sysDirectionMsg: AiMessage = {
    role: "system",
    content: `You are an AI conversation expert program that only goes by the facts you find in the chat conversation itself and are always truthful.
You only respond with JSON`,
  };
  const questionMessage: AiMessage = {
    role: "user",
    content: renderedQuestion,
  };
  // const factContext = factsToAiSystemMessage(
  //   maxEntries({ ...learnedFacts, ...scriptFacts }, 5)
  // );
  // const responsePrefix = `<facts>\n<fact name="`;
  // const conversationText = `<conversation>
  // ${utterancesText}
  // </conversation>`;
  //   const context = `<<SYS>>
  // You are a conversation expert that only goes by the facts you find in the <conversation> itself and are always truthful.

  // <</SYS>>
  // User: ${renderedQuestion}

  // AI:
  // Here is the conversation to analyze:
  // ${conversationText}
  // Here are the known facts:
  // ${factContext}
  // Here are the new facts found in the conversation:
  // ${responsePrefix}`;
  const factsMessage = factsToAiSystemMessage({
    ...learnedFacts,
    ...scriptFacts,
  });
  const response = await askAi({
    model: aiAnalysisModel,

    messages: [
      ...messages,
      factsMessage,
      sysDirectionMsg,
      // sysFormatMsg,
      questionMessage,
    ],
  });
  // const fullResponse = `${responsePrefix}${response}`;
  console.log("---LEARNED---");
  // console.log(fullResponse);
  // const learned = xmlLikeToFacts(fullResponse);
  const learned = safe(() => JSON.parse(response));
  console.log({ response, learned });

  updateChatState((state) => {
    state.learnedFacts = { ...state.learnedFacts, ...learned };
  });
};
