import { AiLogEntry } from "./AiLogEntry";
import { updateAiLogState } from "./updateAiLogState";

export const addLogEntry = (entry: Partial<AiLogEntry>) => {
  const {
    id = crypto.randomUUID(),
    text,
    type,
    time = performance.now(),
  } = entry;
  updateAiLogState((state) => {
    state.log.push({ time, id, text, type });
    if (state.log.length > state.maxLogSize) {
      state.log = state.log.slice(1);
    }
    return state;
  });
  return id;
};
