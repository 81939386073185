import { first } from "@mjtdev/engine";
import { getChatState } from "../ai/useChatState";
import { getOpenAi } from "../ai/askOpenAi";

export const getModelInfo = async () => {
  const { aiBaseUrl } = getChatState();
  const result = await fetch(`${aiBaseUrl}/v1/models`, {
    headers: { "Content-Type": "application/json" },
  });
  const resultWrapper = (await result.json()) as {
    data: {
      id: string;
      object: string;
      created: number;
      owned_by: string;
    }[];
  };
  return first(resultWrapper?.data);
};

export const getModels = () => {
  const { aiType } = getChatState();
  if (aiType === "openAI") {
    return getOpenAiModels();
  }
  if (aiType === "custom") {
    return getCustomModels();
  }
  throw new Error(`Unknown aiType: ${aiType}`);
};

export const getCustomModels = async () => {
  const { aiBaseUrl } = getChatState();
  const result = await fetch(`${aiBaseUrl}/v1/models`, {
    headers: { "Content-Type": "application/json" },
  });
  const resultWrapper = (await result.json()) as {
    data: {
      id: string;
      object: string;
      created: number;
      owned_by: string;
    }[];
  };
  return resultWrapper.data;
};

export const getOpenAiModels = async () => {
  const openai = getOpenAi();
  const list = await openai.models.list();
  console.log({list})
  return list.data;
};
