import { closePopup, createState, openPopup } from "@mjtdev/engine";
import { WaitDisplay } from "./WaitDisplay";
import { openErrorPopup } from "./openErrorPopup";

export const [useWaitState, updateWaitState, getWaitState] = createState({
  actives: [] as string[],
});

export const waitFor = async <T extends object | string>(
  functionOrValue: T | (() => Promise<T> | T),
  options: Partial<{ name: string; message: string }> = {}
): Promise<T> => {
  // const was = document.body.style.cursor;
  document.body.style.cursor = "wait";
  const { bottom } = document.body.getBoundingClientRect();
  const { message = "waiting...", name = crypto.randomUUID() } = options;
  try {
    updateWaitState((state) => {
      state.actives.push(name);
    });
    const { actives } = getWaitState();
    const y = bottom - 30 * (actives.length + 1);
    openPopup(<WaitDisplay name={name}>{message}</WaitDisplay>, {
      showFrame: false,
      name,
      x: 8,
      y,
    });

    const value =
      typeof functionOrValue === "function"
        ? await (functionOrValue as () => Promise<T> | T)()
        : await functionOrValue;
    return value;
  } catch (err) {
    openErrorPopup(err);
    console.error(err);
    throw new Error("Error while waiting");
  } finally {
    document.body.style.cursor = "auto";
    closePopup(name);
    updateWaitState((state) => {
      state.actives = state.actives.filter((a) => a !== name);
    });
  }
};
