import { CSSProperties, ReactNode } from "react";

export const TruncatedText = ({
  style = {},
  children,
}: {
  style?: CSSProperties;
  children: ReactNode;
}) => {
  return (
    <span
      style={{
        maxWidth: "20em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        ...style,
      }}
    >
      {children}
    </span>
  );
};
