import { getBehaviorState } from "../ai/behavior/getBehaviorState";
import { getChatState } from "../ai/useChatState";
import { getTtsState } from "../useTtsState";
import { getSaveDir } from "../file/getSaveDir";
import { writeFilePath } from "../file/writeFilePath";

export type FileMap = Record<string, string | object | Blob>;

export const saveStates = async () => {
  const { pitch, rate, selectedVoice, delay } = getTtsState();
  const { nodes } = getBehaviorState();
  const {
    scriptFacts,
    agentScript,
    guidance,
    aiType,
    goals,
    aiConversationModel,
    aiAnalysisModel,
    temperature,
    presensePenalty,
    maxFactsContextSize,
    maxConversationContextSize,
    greeting,
  } = getChatState();
  const files: FileMap = {
    // "script.txt": agentScript,
    // "guidance.txt": guidance,
    "facts.json": scriptFacts,
    "goals.json": goals.map((g) => ({ ...g, status: undefined })),
    "nodes.json": nodes,
    "tts.json": { pitch, rate, selectedVoice, delay },
    "ai.json": {
      greeting,
      aiType,
      aiConversationModel,
      aiAnalysisModel,
      temperature,
      presensePenalty,
      maxFactsContextSize,
      maxConversationContextSize,
    },
  };
  return saveFileMap(files);
};

export const saveFileMap = async (files: FileMap) => {
  const parent = await getSaveDir();
  for (let key in files) {
    const value = files[key];
    await writeFilePath({
      parent,
      fileName: key,
      content: value,
    });
  }
};
