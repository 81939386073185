import { Grid, PopupDisplay, Reacts } from "@mjtdev/engine";
import { MainToolbar } from "./MainToolbar";
import { MainViewport } from "./MainViewport";

export const App = async () => {
  Reacts.render(
    <Grid cellSize={"min-content"} direction="row">
      <MainToolbar />
      <MainViewport />
      <PopupDisplay />
    </Grid>
  );
};
