import { safeAsync } from "@mjtdev/engine";
import { getAppState } from "../file/AppState";
import { askForPermission } from "../file/askForPermission";
import { openErrorPopup } from "../ui/openErrorPopup";

export const readTextFile = async (src: string) => {
  const { localDirectory } = getAppState();
  const perm = await askForPermission(localDirectory);
  if (perm !== "granted") {
    openErrorPopup(`Unable to get permissions for ${src}`);
    throw new Error(`Error getting permissions for ${src}`);
  }
  return safeAsync(
    async () => {
      const fileHandle = await localDirectory.getFileHandle(src);
      const file = await fileHandle.getFile();
      return file.text();
    },
    {
      onError: () => {
        openErrorPopup(`Error fetching: ${src}`);
        return undefined;
      },
    }
  );
};
