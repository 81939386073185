import { Objects, Randoms } from "@mjtdev/engine";

export const maxEntries = <T extends object>(obj: T, max = 5) => {
  const from = Objects.entries(obj);
  const entries = Randoms.pickRandoms({
    from,
    count: Math.min(max, from.length),
    forceUnique: true,
  });
  console.log({ entries });
  return Objects.fromEntries(entries);
};
