import { isDefined, isUndefined } from "@mjtdev/engine";
import { parseTextToList } from "./parseTextToList";
import { Facts } from "./Facts";

// const PARM_REGEX = /{[\s]*([a-zA-Z0-9\$\-\.]+)[\s]*}\[?([\-0-9]*)\]?/gim;
// const PARM_REGEX = /{[\s]*([a-zA-Z0-9\$\-\.]+)[\s]*}\[([\-0-9]*)\]?/gim;
const PARM_REGEX = /{[\s]*([a-zA-Z0-9\$\-\.]+)[\s]*}\[?([\-0-9]*)/gim;

export const renderTemplate = (template: string, facts: Facts) => {
  if (isUndefined(template)) {
    return undefined;
  }
  return template.replace(PARM_REGEX, (match, factKey, factIndexMaybe) => {
    const fact = facts[factKey];
    // return ""
    if (fact instanceof Blob) {
      return "";
    }
    if (
      typeof fact === "string" &&
      isDefined(factIndexMaybe) &&
      factIndexMaybe.trim().length > 0
    ) {
      const parsedFacts = parseTextToList(fact);
      return parsedFacts.at(Number(factIndexMaybe)) || "";
    }
    if (Array.isArray(fact)) {
      if (isDefined(factIndexMaybe)) {
        return fact[factIndexMaybe] || "";
      }
      return fact.join("\n");
    }
    return String(fact);
  });
};
