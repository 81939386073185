import { AiMessage } from "./AiTypes";

export const aiUtterancesToString = (utterances: AiMessage[]) => {
  return (
    utterances
      // .map((u) => `${u.speaker}: ${u.text.replaceAll(/\n/g, " ").trim()}`)
      .map(
        (u) =>
          `<utterance speaker="${u.name}" text="${u.content
            .replaceAll(/\n/g, " ")
            .trim()}"/>`
      )
      .join("\n")
  );
};
