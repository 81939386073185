import { isUndefined } from "@mjtdev/engine";
import { FormField } from "./FormField";


export const recordToFields = <
  V extends string | boolean | number = string,
  K extends string | number | symbol = string
>(
  record: Record<K, V>
): FormField<K, V>[] => {
  if (isUndefined(record)) {
    return [];
  }
  return Object.entries(record).map(([key, value]) => ({
    name: key as K,
    value: value as V,
  }));
};
