import { last } from "@mjtdev/engine";
import { AppEvents } from "./event/AppEvents";
import { speechRecognitionResultsToUtterances } from "./speechRecognitionResultsToTranscript";
import { getVttState, updateVttState } from "./useVttState";
import { getChatState } from "./ai/useChatState";
import { getPttState } from "./ui/PushToTalk";
import { getTtsState } from "./useTtsState";
import { waitTimeout } from "./waitTimeout";

export const startHearing = async () => {
  const { active } = getChatState();
  if (!active) {
    console.log("refusing to start hearing as chat is inactive");
    return;
  }
  const recognition = new webkitSpeechRecognition();
  recognition.continuous = true;
  recognition.lang = "en-US";
  recognition.interimResults = true;
  updateVttState((state) => {
    state.currentSession = crypto.randomUUID();
    state.sessions.push({
      id: state.currentSession,
      utterances: [],
    });
    state.speechRecognition = recognition;
    state.active = true;
  });

  recognition.onresult = (event) => {
    const { results } = event;
    const utterances = speechRecognitionResultsToUtterances(results);
    updateVttState((state) => {
      const { currentSession, sessions } = state;
      const sessionIdx = sessions.findIndex((s) => s.id === currentSession);
      const session = sessions[sessionIdx];
      session.utterances = utterances;
      const lastUtterance = last(utterances);
      if (lastUtterance.isFinal) {
        const { active: pttActive, talking } = getPttState();
        if (pttActive && !talking) {
          return;
        }
        const { speaking } = getTtsState();
        if (speaking) {
          return;
        }
        AppEvents.dispatchAppEvent("vttUtterance", lastUtterance.text);
      }
    });
  };
  recognition.onend = (event) => {
    setTimeout(() => {
      updateVttState((state) => {
        if (!state.active) {
          return;
        }
        state.currentSession = crypto.randomUUID();
        state.sessions.push({
          id: state.currentSession,
          utterances: [],
        });
        recognition.start();
      });
    }, 10);
  };

  recognition.onerror = (event) => {
    // console.log("ERROR");
    // console.log(event);
  };

  if (getTtsState().speaking) {
    console.log("pausing hearing while talking");
    await waitTimeout(100);
    return startHearing();
  }
  recognition.start();
};
