import { AiLogEntry } from "./AiLogEntry";

export const AiLogEntryDisplay = ({ entry }: { entry: AiLogEntry }) => {
  const { type, text, time } = entry;
  return (
    <div style={{ backgroundColor: "black" }}>
      {time.toFixed(0)}: {text}
    </div>
  );
};
