import { setupAi } from "./ai/setupAi";
import { setupChatbot } from "./ai/setupChatbot";
import { loadAppState } from "./file/AppState";
import { App } from "./ui/App";

(async () => {
  console.log("Build Timestamp: " + __BUILD_TIMESTAMP__);
  console.log("App Version: " + __APP_VERSION__);
  await loadAppState();
  setupAi();
  setupChatbot();
  App();
})();
