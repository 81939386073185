import { Border, Colors, Grid, Objects, isDefined } from "@mjtdev/engine";
import { useChatState } from "../ai/useChatState";
import { COLORMAP } from "./COLORMAP";
import { valueToString } from "./valueToString";

export const FactsDisplay = () => {
  const { scriptFacts, learnedFacts } = useChatState();
  console.log({ scriptFacts, learnedFacts });
  // const contents = goals.map((g, i) => <GoalDisplay key={i} goal={g} />);
  const contents = Objects.entries({ ...learnedFacts, ...scriptFacts })
    .sort((a, b) => String(a[0]).localeCompare(String(b[0])))
    .map((entry, i) => {
      const [key, value] = entry;
      const color = isDefined(learnedFacts[key])
        ? COLORMAP["success"]
        : "white";
      return [
        <div style={{ textAlign: "right", color }} key={String(key)}>
          {valueToString(key)}
        </div>,
        <div key={i}>{valueToString(value)}</div>,
      ];
    });

  return (
    <Border collapsable={true} title="Facts">
      <Grid
        style={{ maxHeight: "60vh", overflow: "auto" }}
        gap={"1em"}
        direction="column"
        cellSize={"min-content"}
        count={2}
      >
        {contents}
      </Grid>
    </Border>
  );
};
