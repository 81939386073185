import { getBackendState, updateBackendState } from "./updateBackendState";


export const closeBackendConnection = () => {
  const { socket } = getBackendState();
  socket.close();
  updateBackendState((state) => {
    state.socket = undefined;
  });
};
