import { safeAsync } from "@mjtdev/engine";
import { openErrorPopup } from "../ui/openErrorPopup";
import { readTextFile } from "./readTextFile";

export const readJsonFile = async (src: string) => {
  return safeAsync(
    async () => {
      const text = await readTextFile(src);
      return JSON.parse(text);
    },
    {
      onError: () => {
        console.log("GOT ERR");
        openErrorPopup(`Error fetching: ${src}`);
        return undefined;
      },
    }
  );
};
