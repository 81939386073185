import { Objects, first, isUndefined } from "@mjtdev/engine";
import { GoalStatus } from "./GoalStatus";

const test = `<answers><"explanation": "The names of the individ…ation": "<no explanation>", "status": "known"}], `;

export const getMatch = (matches: RegExpMatchArray, index: number = 1) => {
  if (isUndefined(matches)) {
    return undefined;
  }
  return matches[index];
};
export const toGoalStatus = (text: string): GoalStatus => {
  return Objects.safe(() => JSON.parse(text), {
    quiet: true,
  });
  // const status = getMatch(/status"="([^"]*)"/gim.exec(text));
  // const answer = getMatch(/answer"="([^"]*)"/gim.exec(text));
  // const explanation = getMatch(/explanation"="([^"]*)"/gim.exec(text));
  // return {
  //   status,
  //   answer,
  //   explanation,
  // };
};
