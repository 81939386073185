import { createState } from "@mjtdev/engine";
import { VttUtterance } from "./speechRecognitionResultsToTranscript";

export type VttSession = {
  id: string;
  utterances: VttUtterance[];
};

export const [useVttState, updateVttState, getVttState] = createState({
  sessions: [] as VttSession[],
  currentSession: undefined as string,
  speechRecognition: undefined as SpeechRecognition,
  active: false,
});
