import { first } from "@mjtdev/engine";
import { OpenApiCompletionsRequest } from "./OpenApiCompletionsRequest";
import { OpenApiResponse } from "./OpenApiResponse";
import { getChatState } from "./useChatState";

export const askRestfulOpenApiCompletions = async (
  prompt: string,
  options: Partial<OpenApiCompletionsRequest> = {}
) => {
  const { aiBaseUrl, aiConversationModel: aiModel } = getChatState();
  const body = {
    prompt,
    ...options,
    //  model: aiModel
  };
  console.log({ body, aiBaseUrl });
  const result = await fetch(`${aiBaseUrl}/v1/completions`, {
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  });
  console.log({ result });
  const json = (await result.json()) as OpenApiResponse;
  const choices = json?.choices || [];
  console.log({ choices });
  return first(choices.map((c) => c?.text));
};
