import { updateBehaviorState } from "../ai/behavior/getBehaviorState";
import { createConversation } from "../ai/createConversation";
import { updateChatState } from "../ai/useChatState";
import { readJsonFile } from "../common/readJsonFile";
import { readTextFile } from "../common/readTextFile";
import { cleanGoals } from "../file/cleanGoals";
import { cleanNodes } from "../file/cleanNodes";
import { updateTtsState } from "../useTtsState";

export const loadStates = async () => {
  const script = await readTextFile(`script.txt`);
  const guidance = await readTextFile(`guidance.txt`);
  const facts = await readJsonFile(`facts.json`);
  const key = await readTextFile(`key.txt`);
  const goals = await readJsonFile(`goals.json`);
  const nodes = await readJsonFile(`nodes.json`);
  const tts = await readJsonFile(`tts.json`);
  const ai = await readJsonFile(`ai.json`);
  updateTtsState((state) => {
    state.delay = tts["delay"];
    state.pitch = tts["pitch"];
    state.rate = tts["rate"];
    state.selectedVoice = tts["selectedVoice"];
  });
  updateBehaviorState((state) => {
    state.nodes = cleanNodes(nodes);
  });

  const {
    aiType = "openAi",
    aiConversationModel = "gpt-3.5-turbo",
    aiAnalysisModel = "gpt-3.5-turbo",
    similarityThreshold = 0.33,
    temperature = 0.5,
    presensePenalty = 2,
    maxFactsContextSize = 5,
    maxConversationContextSize = 5,
    greeting = "Hello this is Intelli-gage",
  } = ai;

  updateChatState((state) => {
    state.scriptFacts = facts;
    state.agentScript = script;
    state.guidance = guidance;
    state.aiType = aiType;
    state.aiConversationModel = aiConversationModel;
    state.aiAnalysisModel = aiAnalysisModel;
    state.aiKey = key;
    state.goals = cleanGoals(goals);
    state.learnedFacts = {};
    state.similarityThreshold = similarityThreshold;
    state.temperature = temperature;
    state.presensePenalty = presensePenalty;
    state.maxFactsContextSize = maxFactsContextSize;
    state.maxConversationContextSize = maxConversationContextSize;
  });
  createConversation();
};
