import { Colors, Grid } from "@mjtdev/engine";
import { ReactNode, useEffect, useRef } from "react";

export const WaitDisplay = ({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) => {
  return (
    <div
      style={{
        backgroundColor: Colors.from("blue")
          .darken(0.8)
          .desaturate(0.5)
          .alpha(0.9)
          .toString(),
      }}
    >
      <Grid
        cellSize={["1.5em", "1fr"]}
        style={{
          borderRadius: "1em",
        }}
      >
        <div>🔴</div>
        {children}
      </Grid>
    </div>
  );
};
