import { Border, Grid } from "@mjtdev/engine";
import { updateChatState, useChatState } from "../ai/useChatState";

export const InteractionInfo = () => {
  const { interactionType } = useChatState();
  return (
    <Border title="interaction" resizable={false}>
      <Grid
        gap={"1em"}
        style={{ whiteSpace: "nowrap" }}
        cellSize={"min-content"}
      >
        <button
          onClick={() => {
            updateChatState((state) => {
              state.interactionType =
                interactionType === "local" ? "backend" : "local";
            });
          }}
        >
          Switch Interaction Location
        </button>
        <div>{interactionType}</div>
      </Grid>
    </Border>
  );
};
