import { askGoalStatus } from "../call-script/askGoalStatus";
import { isGoalComplete } from "./behavior/isGoalComplete";
import { getChatState, updateChatState } from "./useChatState";

export const updateGoals = async () => {
  const { goals } = getChatState();
  const updatedGoals = await Promise.all(
    goals.map(async (g) => {
      // if (isGoalComplete(g.status)) {
      //   return { ...g, active: false };
      // }
      const status = await askGoalStatus(g);
      return { ...g, status };
    })
  );

  updateChatState((state) => {
    state.goals = updatedGoals;
  });
};
