import { isUndefined } from "@mjtdev/engine";


export const toFileContent = (value: string | object | Blob) => {
  if (isUndefined(value)) {
    return "";
  }
  if (value instanceof Blob) {
    return value;
  }
  if (typeof value === "string") {
    return value;
  }
  return JSON.stringify(value, undefined, 2);
};
