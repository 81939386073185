import { Border, Center, Grid } from "@mjtdev/engine";
import { useAiLogState } from "./updateAiLogState";
import { useEffect, useRef } from "react";
import { AiLogEntryDisplay } from "./AiLogEntryDisplay";

export const AiLogDisplay = ({
  style = {},
  contentsStyle = {},
  name,
}: {
  style?: React.CSSProperties;
  contentsStyle?: React.CSSProperties;
  name: string;
}) => {
  const { log } = useAiLogState();
  const bottomRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: "instant",
      });
    }
  }, [log]);
  const contents = log.map((entry, i) => (
    <AiLogEntryDisplay key={i} entry={entry} />
  ));
  return (
    <Border style={{ backgroundColor: "black" }} title="log">
      <Grid style={style} direction="row" cellSize={"min-content"}>
        <Grid style={contentsStyle} direction="row" cellSize={"min-content"}>
          {contents}
          <div ref={bottomRef}></div>
        </Grid>
      </Grid>
    </Border>
  );
};
