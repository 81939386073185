import { sendBackendMessage } from "../backend/sendBackendMessage";
import { AppEvents } from "../event/AppEvents";
import { speak } from "../speak";
import { addLogEntry } from "../ui/log/addLogEntry";
import { waitFor } from "../ui/waitFor";
import { getTtsState } from "../useTtsState";
import { waitTimeout } from "../waitTimeout";
import { askNextBotResponse } from "./askNextBotResponse";
import { Behaviors } from "./behavior/Behaviors";
import { updateGoals } from "./updateGoals";
import { getChatState } from "./useChatState";

export const setupChatbot = () => {
  AppEvents.addAppEventListener("log", (evt) => {
    addLogEntry({ text: evt.detail });
  });

  AppEvents.addAppEventListener("ping", (evt) => {
    const { detail } = evt;
    console.log(`GOT PING: ${detail}`);
    sendBackendMessage({ type: "pong", data: detail });
  });
  AppEvents.addAppEventListener("vttUtterance", async (evt) => {
    const { detail } = evt;
    const { active } = getChatState();
    if (!active) {
      return;
    }
    await askNextBotResponse(detail);
    updateGoals(); // TODO limit re-eval of allready running goal checks?
    await Behaviors.evalTree();
  });
  AppEvents.addAppEventListener("aiUtterance", async (evt) => {
    const { detail } = evt;
    const { active } = getChatState();
    if (!active) {
      return;
    }
    const { interactionType } = getChatState();
    const { delay } = getTtsState();
    await waitFor(waitTimeout(delay), {
      message: `pausing ${delay}ms before speaking`,
    });
    if (interactionType === "local") {
      speak(detail);
    }
    if (interactionType === "backend") {
      sendBackendMessage({
        type: "aiUtterance",
        data: detail,
      });
    }
  });
};
