import { isUndefined } from "@mjtdev/engine";
import { askForPermission } from "./askForPermission";

export const writeFile = async (params: {
  file: FileSystemFileHandle;
  content: string | Blob;
  keepExistingData?: boolean;
}) => {
  const { file, content, keepExistingData } = params;

  const perm = await askForPermission(file, "readwrite");
  if (isUndefined(perm)) {
    return;
  }

  const writable = await file.createWritable({ keepExistingData });
  const writer = writable.getWriter();
  try {
    await writer.write(content);
  } catch (e) {
    console.log({ params });
    console.log(e);
  } finally {
    await writer.close();
  }
};
