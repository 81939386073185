import { Border, Colors, Grid } from "@mjtdev/engine";
import { Goal } from "../call-script/Goal";
import { COLORMAP } from "./COLORMAP";
import { TruncatedText } from "./TruncatedText";
import { valueToString } from "./valueToString";

export const GoalDisplay = ({ goal }: { goal: Goal }) => {
  const active = !goal.active ? undefined : "Active";
  const status = <span>{valueToString(goal?.status?.status) ?? "unknown"}</span>;
  const titleMarker = active ?? status;
  const statusColor = goal.status ? COLORMAP["success"] : COLORMAP["failure"];
  const borderColor = goal.active
    ? Colors.from("blue").lighten(0.5).toString()
    : statusColor;
  return (
    <Border
      collapsable={true}
      style={{
        maxHeight: "5em",
        overflow: "auto",
        width: "30vw",
        border: `3px solid ${borderColor}`,
      }}
      title={
        <span>
          <TruncatedText>{goal.name}</TruncatedText>({titleMarker})
        </span>
      }
    >
      <Grid direction="row" cellSize={"min-content"}>
        <div
        // style={{ border: "1px solid white" }}
        >
          <Grid cellSize={"min-content"} direction="row">
            <div>Answer: {valueToString(goal?.status?.answer)}</div>
            <div>Explanation: {valueToString(goal?.status?.explanation)}</div>
          </Grid>
        </div>
      </Grid>
    </Border>
  );
};


