import { TextGenParams, TextGens } from "@mjtdev/engine";
import { getLastElements } from "../common/getLastElements";
import { AppEvents } from "../event/AppEvents";
import { renderTemplate } from "../template/renderTemplate";
import { aiUtterancesToString } from "./aiUtterancesToString";
import { askAi } from "./askAi";
import { factsToAiSystemMessage } from "./factsToAiSystemMessage";
import { getChatState, updateChatState } from "./useChatState";
import { maxEntries } from "./maxEntries";
import { similarityToStrings } from "./similarityToStrings";
import { waitFor } from "../ui/waitFor";
import { isNotEmpty } from "../common/isNotEmpty";
import { getBotDirection } from "./getBotDirection";
import { AiMessage } from "./AiTypes";

export const askNextBotResponse = async (
  question: string,
  options: Partial<TextGenParams> & Partial<{ maxRetries: number }> = {}
) => {
  const {
    aiConversationModel,
    conversation,
    agentScript,
    scriptFacts = {},
    guidance,
    learnedFacts = {},
    similarityThreshold,
    maxConversationContextSize,
    maxFactsContextSize,
  } = getChatState();
  const { messages } = conversation;

  const factSysMsg = factsToAiSystemMessage(
    maxEntries({ ...learnedFacts, ...scriptFacts }, maxFactsContextSize)
  );

  const renderedScript = renderTemplate(agentScript, scriptFacts);
  const renderedGuidance = renderTemplate(guidance, {
    ...scriptFacts,
    script: renderedScript,
  });

  const aiName = scriptFacts["aiName"] as string;
  const humanName = scriptFacts["prospect"] as string;

  const direction = getBotDirection();

  const utterancesText = aiUtterancesToString(
    getLastElements(messages, maxConversationContextSize)
  );

  const directionAttribute = isNotEmpty(direction)
    ? ` direction="${direction}"`
    : "";

  //   const context = `<<SYS>>
  // ${renderedGuidance}
  // <</SYS>>
  // User: Follow the directions and respond as ${aiName} Do not call the customer by name. Be conversational and polite. Talk lke a real person not like a robot.
  // AI: ${factContext}
  // <conversation>${utterancesText}
  // <utterance speaker="${humanName}" text="${question}"/>
  // <utterance speaker="${aiName}"${directionAttribute} text="`;

  const userMessage: AiMessage = {
    content: question,
    role: "user",
    name: humanName,
  };
  updateChatState((state) => {
    state.waitingOnAi = true;
  });
  // console.log("context-length: " + TextGens.textToTokens(context).length);
  const response = await askAi({
    // stopping_strings: [
    //   `${aiName}:`,
    //   `${humanName}:`,
    //   `You:`,
    //   `AI:`,
    //   "User:",
    //   `<utterance speaker="${humanName}"`,
    // ],
    messages: [...messages, factSysMsg, userMessage],
    model: aiConversationModel,
    ...options,
  });
  // console.log(response);
  // const cleanedResponse = response.replace(/".*/gim, "").replace(/<.*/gim, "");
  const cleanedResponse = response
    .replace(new RegExp(`^${aiName}:`, "igm"), "")
    .trim();
  // const cleanedResponse = response;

  const botResponses = messages
    .filter((u) => u.name === scriptFacts["aiName"])
    .map((u) => u.content);

  const similarity = similarityToStrings(cleanedResponse, botResponses);
  const { maxRetries = 3 } = options;
  if (similarity > similarityThreshold && maxRetries > 0) {
    console.log("Response was too similar, retrying");
    console.log({ similarity, options, cleanedResponse });
    return waitFor(
      askNextBotResponse(question, {
        ...options,
        maxRetries: maxRetries - 1,
      }),
      {
        message: `Too similar response. similarity: ${similarity} maxRetries: ${maxRetries}`,
      }
    );
  }
  AppEvents.dispatchAppEvent("aiUtterance", cleanedResponse);
  updateChatState((state) => {
    state.waitingOnAi = false;
    state.conversation = {
      ...state.conversation,
      messages: [
        ...state.conversation.messages,
        { name: humanName, content: question, role: "user" },
        { name: aiName, content: cleanedResponse, role: "assistant" },
      ],
    };
  });

  // await updateGoals(goals);
};
