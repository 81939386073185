import { Objects, openCenteredPopup } from "@mjtdev/engine";
import { PopupForm } from "./PopupForm";
import { PopupFormDisplay } from "./PopupFormDisplay";
import { FieldDisplay } from "./FieldDisplay";
import { recordToFields } from "./recordToFields";
import { convertValueToType } from "./convertValueToType";
import { isEmpty } from "../../common/isEmpty";

export const openFormPopup = <
  V extends string | boolean | number = string,
  K extends string | number | symbol = string
>({
  title = "Form",
  record,
  fields = recordToFields(record),
  style,
}: Partial<PopupForm<K, V>> = {}): Promise<Record<K, V>> => {
  const realizedFields = fields.map((f) => ({
    name: f.name,
    value: isEmpty(f?.value) ? undefined : f.value,
  }));
  const fieldTypeMap = Objects.fromEntries(
    realizedFields.map((f) => [f.name, typeof f.value] as const)
  );
  const result = realizedFields.reduce((acc, cur) => {
    acc[cur.name as K] = cur.value;
    return acc;
  }, {} as Record<K, V>);
  return new Promise((resolve, reject) => {
    {
      const fieldComponents = realizedFields.map((f, i) => (
        <FieldDisplay
          onChange={(value) => {
            result[f.name] = convertValueToType(
              f.name,
              value,
              fieldTypeMap
            ) as any;
          }}
          field={f}
          key={i}
          autoFocus={i === 0}
        />
      ));

      const name = crypto.randomUUID();

      openCenteredPopup(
        <PopupFormDisplay
          onSubmit={(ok) => {
            if (!ok) {
              resolve(undefined);
            }
            resolve(result);
          }}
          name={name}
          title={title}
          fieldComponents={fieldComponents}
          style={style}
        ></PopupFormDisplay>,
        {
          name,
        }
      );
    }
  });
};
