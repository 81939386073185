import { createState, isUndefined } from "@mjtdev/engine";
import { Facts } from "../template/Facts";
import { AiConversation } from "./AiTypes";
import { Goal } from "../call-script/Goal";
import { AI_TYPES } from "../ui/AI_TYPES";

// const script = `1. Standard Outreach Call
// A standard outreach call serves as an opportunity for you to connect with a prospect one-on-one and begin to guide them toward the next step in the buying process. Prior to making this call, be sure to conduct research about the prospect and their business as an initial qualification measure.
// By taking the time to conduct research prior to the call, you’re setting yourself up for a more contextual, warm conversation. You can avoid wasting a prospect’s time by looking into the following ahead of time: identifying their job title and current role expectations, exploring the company website and social accounts, and using Crunchbase to identify your prospect's acquisition history, funding rounds, investors, team members, news, timeline, competitors, former employees, customers, partners, sub-organizations, board members and advisors, and other related details.
// For more tips on where and how to research a prospect, check out this blog post.
// Template:
// Hi {prospect}, this is {aiName} from {companyName}.
// I’ve been doing some research on {prospectCompanyName} and I’d love to learn more about {commonBuyerPersonaChallenges}.
// At {companyName} we work with people like you to help with {valuePropositions}
// Is this something you think could help with {commonBuyerPersonaChallenges}?
// Option 1: Yes, tell me more.
// Great! [This where you’re going to ask them to attend a demo, or continue the conversation with an Account Executive, or take whatever next steps are part of your sales process.]
// Option 2: Objection
// I understand. Is it ok if I send you a follow up email to review at your convenience? Then I can follow up with you tomorrow. [If yes, send the email and set a reminder to follow up. If no, thank them for their time and ask if there’s another point of contact they can connect you with.]
//  up with an email. This may be a better way to connect. Make sure to include resources that clearly explain what your company does and ask to continue the conversation].`;

// export const facts: Facts = {
//   prospect: "Kevin",
//   aiName: "Brian",
//   companyName: "Intelli-gage",
//   prospectCompanyName: "ACME Tools",
//   commonBuyerPersonaChallenges: "your need for more customers",
//   valuePropositions: "getting more people into your pipeline",
// };
export type AiType = (typeof AI_TYPES)[number];
export type InteractionLocation = "local" | "backend";

export const [useChatState, updateChatState, getChatState] = createState({
  seed: undefined as number,
  temperature: 0.5 as number,
  presensePenalty: 2 as number,
  maxConversationContextSize: 5,
  maxFactsContextSize: 5,
  conversation: undefined as AiConversation,
  waitingOnAi: false,
  active: false,
  agentScript: undefined as string,
  guidance: undefined as string,
  scriptFacts: undefined as Facts,
  learnedFacts: undefined as Facts,
  aiKey: undefined as string,
  aiType: "custom" as AiType,
  aiConversationModel: "gpt-3.5-turbo",
  aiAnalysisModel: "gpt-3.5-turbo",
  aiBaseUrl: "http://gamepc.local:5000",
  backendHost: "intellibot.mjtdev.workers.dev",
  interactionType: "local" as InteractionLocation,
  similarityThreshold: 0.33,
  greeting: "Hello this is intelli-gage",
  goals: [] as Goal[],
});
