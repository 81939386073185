import { Colors, Popups, isPopupOpen, openPopup } from "@mjtdev/engine";
import { AiLogDisplay } from "./AiLogDisplay";
import { AI_LOG_POPUP_NAME } from "./AI_LOG_POPUP_NAME";

export const showAiLog = () => {
  const name = AI_LOG_POPUP_NAME;
  if (!isPopupOpen(name)) {
    openPopup(
      <AiLogDisplay
        style={{
          backgroundColor: Colors.from("black").alpha(0.8).toString(),
        }}
        contentsStyle={{
          overflow: "auto",
          maxHeight: "30vh",
          maxWidth: "30vw",
        }}
        name={name}
      />,
      {
        name,
      }
    );
  }
  Popups.showPopup(name);
};
