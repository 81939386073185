import { Border, ContentEditable, isUndefined } from "@mjtdev/engine";
import { FormField } from "./FormField";
import { CSSProperties, ReactNode } from "react";

type FieldDisplayType = "string" | "number" | "boolean";
type FieldDisplayConstructor<T extends FieldDisplayType> = (
  name: string,
  value: T,
  autoFocus: boolean,
  onChange?: (value: string) => void
) => ReactNode;

const FIELD_STYLE: CSSProperties = {
  minHeight: "1.5em",
  minWidth: "40em",
  overflow: "visible",
};

const VALUE_TYPE_TO_DISPlAY: {
  [k in FieldDisplayType]: FieldDisplayConstructor<k>;
} = {
  number: (name, value, autoFocus, onChange) => (
    // <ContentEditable
    //   key={name}
    //   value={String(value)}
    //   autoFocus={autoFocus}
    //   style={{
    //     ...FIELD_STYLE,
    //   }}
    //   onChange={(v) => onChange(v)}
    // />
    <input
      style={FIELD_STYLE}
      autoFocus={autoFocus}
      defaultValue={value}
      onChange={(evt) => onChange(evt.target.value)}
      type="input"
    />
  ),
  string: (name, value, autoFocus, onChange) => (
    <ContentEditable
      key={name}
      value={value}
      autoFocus={autoFocus}
      style={{
        ...FIELD_STYLE,
      }}
      onChange={(v) => onChange(v)}
    />
    // <input
    //   style={FIELD_STYLE}
    //   autoFocus={autoFocus}
    //   defaultValue={value}
    //   onChange={(evt) => onChange(evt.target.value)}
    //   type="input"
    // />
  ),
  boolean: (name, value, autoFocus, onChange) => (
    <input
      autoFocus={autoFocus}
      defaultChecked={Boolean(value)}
      onChange={(evt) => onChange(String(evt.target.checked))}
      type="checkbox"
    />
  ),
};

export const FieldDisplay = ({
  autoFocus,
  field,
  onChange = () => {},
}: {
  autoFocus: boolean;
  onChange?: (value: string) => void;
  field: FormField<any, any>;
}) => {
  const fieldType =
    typeof field.value === "undefined" ? "string" : typeof field.value;
  const displayConstructor = VALUE_TYPE_TO_DISPlAY[fieldType];
  if (isUndefined(displayConstructor)) {
    return undefined;
  }

  const editableDisplay = displayConstructor(
    field.name,
    field.value,
    autoFocus,
    onChange
  );
  return (
    <Border resizable={true} title={String(field.name)}>
      {editableDisplay}
    </Border>
  );
};
