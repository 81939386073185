// import { importGameUrl } from "../game/importGameUrl";
// import { HINTS_TEXT } from "./HINTS_TEXT";
// import { MainGameWindow } from "./MainGameWindow";
// import { TextScreen } from "./TextScreen";
// import { WELCOME_TEXT } from "./WELCOM_TEXT";
// import { openAppSettingsPopup } from "./openAppSettingsPopup";
// import { openStartNewGamePopup } from "./openStartNewGamePopup";
export const WELCOME_TEXT = `

Welcome to the Intellibot!



`;
