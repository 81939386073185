import { Reacts } from "@mjtdev/engine";
import { CustomEventHandler } from "@mjtdev/engine/packages/mjtdev-reacts/dist/hook/addCustomEventListener";

export type AppEventMap = {
  vttUtterance: string;
  aiUtterance: string;
  log: string;
  ping: string;
};

export type AppEventType = keyof AppEventMap;

export const dispatchAppEvent = <ET extends keyof AppEventMap>(
  eventType: ET,
  payload: AppEventMap[ET]
) => {
  Reacts.dispatchCustomEvent(eventType, payload);
};

export const addAppEventListener = <ET extends keyof AppEventMap>(
  eventType: ET,
  handler: CustomEventHandler<AppEventMap[ET]>
) => {
  return Reacts.addCustomEventListener(eventType, handler);
};

export const useAppEventListener = <ET extends keyof AppEventMap>(
  eventType: ET,
  action: (e: CustomEvent<AppEventMap[ET]>) => void
) => {
  return Reacts.useCustomEventListener(eventType, action);
};

export const AppEvents = {
  addAppEventListener,
  useAppEventListener,
  dispatchAppEvent,
};
