import { isUndefined } from "@mjtdev/engine";
import { closeBackendConnection } from "../backend/closeBackendConnection";
import { openBackendConnection } from "../backend/openBackendConnection";
import { useBackendState } from "../backend/updateBackendState";


export const BackendDisplay = () => {
  const { socket } = useBackendState();
  if (isUndefined(socket)) {
    return (
      <button
        onClick={() => {
          openBackendConnection();
        }}
        style={{ whiteSpace: "nowrap", width: "min-content" }}
      >
        Connect Backend
      </button>
    );
  } else {
    return (
      <button
        onClick={() => {
          closeBackendConnection();
        }}
        style={{ whiteSpace: "nowrap", width: "min-content" }}
      >
        Disconnect Backend
      </button>
    );
  }
};
