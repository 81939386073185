import { isDefined } from "@mjtdev/engine";
import { getVttState, updateVttState } from "./useVttState";

export const stopHearing = () => {
  const { speechRecognition } = getVttState();
  return new Promise((resolve, reject) => {
    if (isDefined(speechRecognition)) {
      speechRecognition.onaudioend = (event) => {
        resolve(undefined);
      };
    } else {
      resolve(undefined);
    }
    updateVttState((state) => {
      state.speechRecognition = undefined;
      state.active = false;
    });
    speechRecognition?.stop();
  });
};
