import { Border, Grid } from "@mjtdev/engine";
import { useEffect, useState } from "react";
import { AiType, updateChatState, useChatState } from "../ai/useChatState";
import { getModelInfo, getModels } from "./getModelInfo";
import { AI_TYPES } from "./AI_TYPES";
import { Model } from "openai/resources";

// export const OPEN_AI_MODELS = [
//   "gpt-3.5-turbo",
//   "gpt-4",
//   "gpt-4-32k",
//   "gpt-3.5-turbo-1106",
// ];

export const AiInfo = () => {
  const {
    aiType,
    aiBaseUrl,
    aiKey,
    aiConversationModel,
    aiAnalysisModel,
    similarityThreshold,
    temperature,
    presensePenalty,
    maxConversationContextSize,
    maxFactsContextSize,
    greeting,
  } = useChatState();

  const [models, setModels] = useState([] as Model[]);
  useEffect(() => {
    getModels().then((models) => setModels(models));
  }, [aiType, aiBaseUrl, aiKey]);
  return (
    <Border title="ai">
      <Grid
        gap={"1em"}
        style={{ whiteSpace: "nowrap" }}
        cellSize={"min-content"}
      >
        <Border title="conversation model">
          <select
            value={aiConversationModel}
            onChange={(evt) => {
              updateChatState((state) => {
                state.aiConversationModel = evt.target.value;
              });
            }}
          >
            {models.map((m, i) => (
              <option key={i} value={m.id}>
                {m.id}
              </option>
            ))}
          </select>
        </Border>
        <Border title="analysis model">
          <select
            value={aiConversationModel}
            onChange={(evt) => {
              updateChatState((state) => {
                state.aiAnalysisModel = evt.target.value;
              });
            }}
          >
            {models.map((m, i) => (
              <option key={i} value={m.id}>
                {m.id}
              </option>
            ))}
          </select>
        </Border>
        <Border title="type">
          <select
            value={aiType}
            onChange={(evt) => {
              updateChatState((state) => {
                console.log(evt.target.value);
                state.aiType = evt.target.value as AiType;
              });
            }}
          >
            {AI_TYPES.map((m, i) => (
              <option key={i} value={m}>
                {m}
              </option>
            ))}
          </select>
        </Border>
      </Grid>

      <Grid
        style={{ whiteSpace: "nowrap" }}
        cellSize={"min-content"}
        count={2}
        direction="column"
      >
        <Border resizable={false} title="key">
          <input
            onChange={(evt) => {
              updateChatState((state) => {
                state.aiKey = evt.target.value;
              });
            }}
            type="password"
            defaultValue={aiKey}
          />
        </Border>
        <Border resizable={false} title="base URL">
          <input
            onChange={(evt) => {
              updateChatState((state) => {
                const rawValue = evt.target.value.trim();
                const value = rawValue.startsWith("http://")
                  ? rawValue
                  : "http://" + rawValue;
                state.aiBaseUrl = value;
              });
            }}
            type="text"
            defaultValue={aiBaseUrl}
          />
        </Border>
      </Grid>
      <Border
        resizable={false}
        style={{ whiteSpace: "nowrap" }}
        title={`similarity threshold ${(similarityThreshold * 100).toFixed(
          0
        )}%`}
      >
        <input
          type="range"
          min="0"
          max="100"
          step={"1"}
          defaultValue={`${similarityThreshold * 100}`}
          onChange={(evt) => {
            updateChatState((state) => {
              state.similarityThreshold = Number(
                (Number(evt.target.value) / 100).toFixed(2)
              );
            });
          }}
        />
      </Border>
      <Border
        resizable={false}
        style={{ whiteSpace: "nowrap" }}
        title={`temperature ${temperature}`}
      >
        <input
          type="range"
          min="0"
          max="1"
          step={"0.01"}
          defaultValue={`${temperature}`}
          onChange={(evt) => {
            updateChatState((state) => {
              state.temperature = Number(evt.target.value);
            });
          }}
        />
      </Border>
      <Border
        resizable={false}
        style={{ whiteSpace: "nowrap" }}
        title={`presense penalty ${presensePenalty}`}
      >
        <input
          type="range"
          min="-2"
          max="2"
          step={"0.01"}
          defaultValue={`${presensePenalty}`}
          onChange={(evt) => {
            updateChatState((state) => {
              state.presensePenalty = Number(evt.target.value);
            });
          }}
        />
      </Border>
      <Border
        resizable={false}
        style={{ whiteSpace: "nowrap" }}
        title={`conversation context size ${maxConversationContextSize}`}
      >
        <input
          type="range"
          min="1"
          max="1000"
          step={"1"}
          defaultValue={`${maxConversationContextSize}`}
          onChange={(evt) => {
            updateChatState((state) => {
              state.maxConversationContextSize = Number(evt.target.value);
            });
          }}
        />
      </Border>
      <Border
        resizable={false}
        style={{ whiteSpace: "nowrap" }}
        title={`facts context size ${maxFactsContextSize}`}
      >
        <input
          type="range"
          min="1"
          max="1000"
          step={"1"}
          defaultValue={`${maxFactsContextSize}`}
          onChange={(evt) => {
            updateChatState((state) => {
              state.maxFactsContextSize = Number(evt.target.value);
            });
          }}
        />
      </Border>
      <Border
        resizable={false}
        style={{ whiteSpace: "nowrap" }}
        title={`greeting`}
      >
        <textarea
          value={greeting}
          onChange={(evt) => {
            updateChatState((state) => {
              state.greeting = evt.target.value;
            });
          }}
        />
      </Border>
    </Border>
  );
};
