import { Center, Grid } from "@mjtdev/engine";
import { WINDOWS, switchWindow } from "./WINDOWS";
import { Objects } from "@mjtdev/engine";

export const MainToolbar = () => {
  const items = Objects.entries(WINDOWS).map((entry, index) => {
    const [name, node] = entry;
    return (
      <button
        key={index}
        onClick={() => {
          switchWindow(name);
        }}
      >
        {name}
      </button>
    );
  });

  return (
    <Center>
      <Grid cellSize={"min-content"}>{items}</Grid>
    </Center>
  );
};
