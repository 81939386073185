import { Border, Center, Grid, isUndefined } from "@mjtdev/engine";
import { loadStates } from "../state/loadStates";
import { getAppState } from "../file/AppState";
import { getChatState, useChatState } from "../ai/useChatState";
import { useEffect } from "react";
import { FactsEditor } from "./FactsEditor";
import { GoalsEditor } from "./GoalsEditor";
import { VoiceControlsDisplay } from "./VoiceControlsDisplay";
import { AiInfo } from "./AiInfo";
import { InteractionInfo } from "./InteractionInfo";
import { SaveLoadDisplay } from "./SaveLoadDisplay";
import { getVttState } from "../useVttState";
import { getTtsState } from "../useTtsState";
import { play } from "../template/play";

export const ConfigWindow = () => {
  const { agentScript, guidance } = useChatState();
  useEffect(() => {
    if (isUndefined(agentScript)) {
      loadStates();
    }
  }, []);
  if (isUndefined(agentScript)) {
    // return <>Loading...</>;
    return <SaveLoadDisplay />;
  }
  return (
    <Center style={{ width: "80vw", height: "80vh", overflow: "auto" }}>
      <div>Intellibot build: {__BUILD_TIMESTAMP__}</div>
      <Border title="debug" resizable={false}>
        <button
          onClick={() => {
            const chatState = getChatState();
            const vttState = getVttState();
            const ttsState = getTtsState();
            const appState = getAppState();
            console.log({ chatState, vttState, ttsState, appState });
          }}
        >
          Dump State
        </button>
        <button
          onClick={() => {
            play();
          }}
        >
          Test
        </button>
      </Border>
      <SaveLoadDisplay />
      <AiInfo />
      <InteractionInfo />
      <VoiceControlsDisplay />
      <Grid
        direction="column"
        cellSize={"min-content"}
        style={{ whiteSpace: "nowrap" }}
      >
        <FactsEditor />
        <GoalsEditor />
      </Grid>
    </Center>
  );
};
