import { Border, Grid, last } from "@mjtdev/engine";
import { useVttState } from "../useVttState";
import { updateChatState, useChatState } from "../ai/useChatState";
import { useEffect, useRef } from "react";
import { ControlsDisplay } from "./ControlsDisplay";

export const ChatBox = () => {
  const { sessions } = useVttState();
  const { conversation, waitingOnAi } = useChatState();
  const ref = useRef<HTMLDivElement>();
  const lastVttUtterance = last(last(sessions ?? [])?.utterances);
  const speaking =
    !lastVttUtterance?.isFinal || waitingOnAi ? (
      <span style={{ color: "grey" }}>You: {lastVttUtterance?.text ?? ""}</span>
    ) : (
      ""
    );
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "instant" });
    }
  }, [conversation, ref, speaking]);
  const title = (
    <>
      Conversation <ControlsDisplay />
    </>
  );
  return (
    <Border title={title}>
      <Grid
        style={{ overflow: "auto", maxHeight: "75vh" }}
        direction="row"
        cellSize={"min-content"}
      >
        {conversation.messages.map((s, i) => (
          <div key={i}>
            {s.name}: {s.content}{" "}
            <button
              onClick={() => {
                updateChatState((state) => {
                  state.conversation.messages =
                    state.conversation.messages.filter((u, ii) => ii !== i);
                });
              }}
            >
              X
            </button>
          </div>
        ))}
        <div ref={ref}>{speaking}</div>
      </Grid>
    </Border>
  );
};
