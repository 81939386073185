import { closePopup, openCenteredPopup } from "@mjtdev/engine";
import { FileOrDirHandle } from "./FileOrDirHandle";

export const askForPermission = async (
  handle: FileOrDirHandle,
  mode: "read" | "readwrite" = "read"
): Promise<PermissionState> => {
  const perm = await handle.queryPermission({
    mode,
  });
  if (perm === "granted") {
    return perm;
  }
  return new Promise((resolve) => {
    const name = openCenteredPopup(
      <button
        onClick={async () => {
          const perm = await handle.requestPermission();
          closePopup(name);
          resolve(perm);
        }}
      >
        Need Perm for {handle.name}
      </button>
    );
  });
};
